import {ActionType} from "../action-types";
import {SearchEntityAction} from "../actions/search.action";

//Entity can be bed or room
interface EntityState {
  loading: boolean;
  error: string | null;
  success: boolean | null;
  check_in_date: string | null;
  check_out_date: string | null;
  exam_date: string | null;
  room_type: string | null;
  not_for_exam: boolean;
  entity: {
    data: any[],
    total: number,
    next_page: any,
    previous_page: any
  } | null;
}

const initialState = {
  loading: false,
  error: null,
  success: false,
  check_in_date: null,
  check_out_date: null,
  exam_date: null,
  room_type: null,
  not_for_exam: false,
  entity: null,
};

const bedsReducer = (state: EntityState = initialState, action: SearchEntityAction): EntityState => {
  switch (action.type) {
    case ActionType.SEARCH_ENTITY_START:
      return {
        ...state,
        check_in_date: action.check_in_date,
        check_out_date: action.check_out_date,
        exam_date: action.exam_date,
        room_type: action.room_type,
        not_for_exam: action.not_for_exam,
        loading: true,
        error: null,
        success: false,
        entity: null,
      };
    case ActionType.SEARCH_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        entity: action.payload,
      };
    case ActionType.SEARCH_ENTITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
        entity: null,
      };
    case ActionType.SEARCH_CLEAR_ENTITY:
      return {
        ...state,
        check_in_date: "",
        check_out_date: "",
        exam_date: "",
        room_type: "",
        not_for_exam: false,
        loading: false,
        error: null,
        success: true,
        entity: null,
      };
    case ActionType.SEARCH_ENTITY_CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};


export default bedsReducer;