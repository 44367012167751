import React, { useCallback } from "react";
import { ToastContainer } from "react-toastify";
import { useActions } from "./hooks/useActions";
import Routes from "./routes";

function App() {
  const { authCheckState } = useActions();
  const checkAuth = useCallback(() => {
    // console.log("checking state...");
    authCheckState();
  }, [authCheckState]);

  checkAuth();

  console.log(process.env.NODE_ENV);

  return (
    <>
      <Routes />
      <ToastContainer
        position="top-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
