import { ActionType } from "../action-types";
import { AuthAction } from "../actions/auth.actions";
import { Dispatch } from "redux";
import axios from "../../axios-fetches";
import plain_axios from "../../client-axios";
import { toast } from "react-toastify";
import { detectError } from "../../utilities";

export const signOut = () => {
  return (dispatch: Dispatch<AuthAction>) => {
    dispatch({
      type: ActionType.SIGNOUT_START,
    });

    try {
      localStorage.removeItem("user");
      dispatch({
        type: ActionType.SIGNOUT_SUCCESS,
      });
    } catch (error: any) {
      dispatch({
        type: ActionType.SIGNOUT_ERROR,
        payload: error.message,
      });
    }
  };
};

export const signinUser = (user: { email: string; password: string }) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    dispatch({
      type: ActionType.AUTH_START,
    });
    try {
      let { data } = await plain_axios.post(
        "/auth/jwt/create",
        user
      );

      const accessToken = data.access;
      let date = new Date();
      const expiresIn = date.setDate(date.getDate() + 1);
      // const expiresIn = new Date(date.getTime() + (5*60000)) // 5 minutes
      // @ts-ignore
      // const expiresIn = new Date(date - 60 * 60000); // expire 1 hr before

      let result = await plain_axios.get(
        "/auth/users/me/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const user_cred = {
        id: result.data.id,
        username: result.data.username,
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        email: result.data.email,
        phone: result.data.phone,
        is_superuser: result.data.is_superuser,
        is_staff: result.data.is_staff,
        expiresIn: expiresIn,
        accessToken: accessToken,
      };
      localStorage.setItem("user", JSON.stringify(user_cred));

      const expires = new Date(expiresIn)
      const now = new Date()
      const expMilliSeconds = expires.getTime() - now.getTime()

      setTimeout(() => {
        // @ts-ignore
        dispatch(signOut());
      }, expMilliSeconds);
      dispatch({
        type: ActionType.AUTH_SUCCESS,
        payload: user_cred,
      });
    } catch (error: any) {
      // console.dir(error);
      let message = "incorrect email or password";
      if (!message) {
        message = "Something went wrong, Please try again";
      }

      dispatch({
        type: ActionType.AUTH_ERROR,
        payload: message,
      });
    }
  };
};

//Auto sign in on reload
export const authCheckState = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    // Get token userId, and exp date from local storage
    //to update to indexed db later
    let authuser = localStorage.getItem("user");
    if (!authuser) {
      // @ts-ignore
      dispatch(signOut());
    } else {
      const { id, expiresIn, username, email, is_staff, first_name, last_name, phone, is_superuser } =
        JSON.parse(authuser);

      const expirationDate = new Date(expiresIn);
      if (expirationDate < new Date()) {
        // @ts-ignore
        dispatch(signOut());
      } else {
        dispatch({
          type: ActionType.AUTH_SUCCESS,
          payload: {
            id,
            username,
            email,
            is_staff,
            first_name,
            last_name,
            phone,
            is_superuser
          },
        });
        const expires = new Date(expiresIn)
        const now = new Date()
        // const expMilliSeconds = +new Date(expiresIn) - +new Date()
        const expMilliSeconds = expires.getTime() - now.getTime()
        console.log("Creating timeout");
        setTimeout(() => {
          // @ts-ignore
          dispatch(signOut());
        }, expMilliSeconds);
      }
    }
  };
};

export const getCurrentUser = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    dispatch({
      type: ActionType.CURRENT_USER_START,
    });

    try {
      const response = await axios.get("auth/users/me");
      dispatch({
        type: ActionType.CURRENT_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      console.log(error?.response?.data);
      let message = "Unable to connect";
      if (error?.response) {
        if (Array.isArray(error.response.data?.message)) {
          message = error.response.data.message.join("\n");
        } else {
          message = error?.response.data?.message;
        }
      }
      if (!message) {
        message = "Something went wrong";
      }
      dispatch({
        type: ActionType.CURRENT_USER_ERROR,
        payload: message,
      });
    }
  };
};

export const updateUser= (data: any) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      const user = localStorage.getItem('user')
      let userData;
      if(user){
        userData = JSON.parse(user)
      }
      const user_cred = {
        id: data.id,
        username: data.username,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        is_superuser: data.is_superuser,
        is_staff: data.is_staff,
        expiresIn: userData?.expiresIn,
        accessToken: userData?.accessToken,
      };

      localStorage.setItem("user", JSON.stringify(user_cred));

      dispatch({
        type: ActionType.AUTH_SUCCESS,
        payload: user_cred,
      });
    } catch (e) {
      const message = detectError(e)
      toast(message);
    }
  }
}

export const clearError = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    dispatch({
      type: ActionType.CLEAR_ERROR,
    });
  };
};
