import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { loadState, saveState } from "./localStorage";
import reducers from "./reducers";
import throttle from 'lodash/throttle';

// Activate the chrome extension
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistedState = loadState();

export const store = createStore(
  reducers,
    persistedState,
  composeEnhancers(applyMiddleware(thunk))
);


store.subscribe(throttle(() => {
  saveState({
    entities: store.getState().entities
  });
}, 1000));