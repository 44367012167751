import { combineReducers } from "redux";
import authReducer from "./auth.reducers";
import housesReducer from "./houses.reducers";
import searchReducers from "./search.reducers";
import searchBookingsReducer from "./search.bookings.reducers";
import statusBookingsReducers from "./status.bookings.reducers";
import refundsReducer from "./refunds.reducer";

const reducers = combineReducers({
  auth: authReducer,
  houses: housesReducer,
  entities: searchReducers,
  sbookings: searchBookingsReducer,
  statusBookings: statusBookingsReducers,
  refundData: refundsReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
