import { ActionType } from "../action-types";
import { AuthAction } from "../actions/auth.actions";

export type User = {
  id: number | null;
  username: string | null;
  email: string | null;
  phone: string | null;
  first_name: string | null;
  last_name: string | null;
  is_staff: boolean | null;
  is_superuser: boolean | null;
};

interface AuthState {
  loading: boolean;
  error: string | null;
  success: boolean;
  user: User | null;
}

const initialState = {
  loading: false,
  error: null,
  success: false,
  user: null,
};

const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case ActionType.AUTH_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        user: null,
      };
    case ActionType.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case ActionType.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: {
          id: action.payload.id,
          username: action.payload.username,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          phone: action.payload.phone,
          is_staff: action.payload.is_staff,
          is_superuser: action.payload.is_superuser
        },
        success: true,
      };
    case ActionType.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        user: null,
        success: false,
      };
    case ActionType.SIGNOUT_START:
      return {
        ...state,
        loading: true,
        error: null,
        user: null,
        success: false,
      };
    case ActionType.SIGNOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: null,
        success: true,
      };
    case ActionType.SIGNOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionType.CURRENT_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        user: null,
      };
    case ActionType.CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        user: {
          id: action.payload.id,
          username: action.payload.username,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          phone: action.payload.phone,
          is_staff: action.payload.is_staff,
          is_superuser: action.payload.is_superuser
        },
      };
    case ActionType.CURRENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
