import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import axios from "../../client-axios";
import { HousesAction } from "../actions/houses.action";
import { detectError } from "../../utilities";

export const getHouses = () => {
  return async (dispatch: Dispatch<HousesAction>) => {
    dispatch({
      type: ActionType.GET_HOUSES_START,
    });
    try {
      const res = await axios.get("/api/houses");
      dispatch({
        type: ActionType.GET_HOUSES_SUCCESS,
        payload: res.data.results,
      });
    } catch (e) {
      console.log(e);
      const message = detectError(e);
      dispatch({
        type: ActionType.GET_HOUSES_ERROR,
        payload: message,
      });
    }
  };
};
