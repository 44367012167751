import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import axios from "../../axios-fetches";
import { GetStatusBookingAction } from "../actions/status.bookings.action";
import { detectError } from "../../utilities";

export const getStatusBookings = (house_id: number) => {
  return async (dispatch: Dispatch<GetStatusBookingAction>) => {
    dispatch({
      type: ActionType.GET_STATUS_BOOKINGS_START,
    });
    try {
      const res = await axios.get(`/api/bookingspercheck/${house_id}`);
      dispatch({
        type: ActionType.GET_STATUS_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      const message = detectError(e);
      dispatch({
        type: ActionType.GET_STATUS_BOOKINGS_ERROR,
        payload: message
      });
    }
  };
};

export const clearStatusBookings = () =>  {
  return (dispatch: Dispatch<GetStatusBookingAction>) => {
    dispatch({
      type: ActionType.CLEAR_STATUS_BOOKINGS
    })
  }
}
