import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AuthenticatedRoute from "./hoc/AuthenticatedRoute";
import LandingPage from "./Pages/LandingPage";
import Login from "./Pages/Login";
import NotFound from "./Pages/NotFound";

const Admin = React.lazy(() => import("./Pages/Admin"));

type Props = {};

const Routes: React.FC<Props> = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/admin/login"
          exact
          render={(props) => {
            return <Login {...props} />;
          }}
        />
        <Route
          path="/admin"
          render={(props) => (
            <Suspense
              fallback={
                <div className="d-flex justify-content-center align-items-center fw-bold h-100">
                  Loading...
                </div>
              }
            >
              <AuthenticatedRoute Component={Admin} {...props} />
            </Suspense>
          )}
        />
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
