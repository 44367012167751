import { ActionType } from "../action-types";
import { HousesAction } from "../actions/houses.action";

export type House = {
    id: number;
    name: string;
    slug: string;
    description: string;
    address: string;
    gender: string;
    display_address: string;
    average_bed_cost: number;
    check_in_time: string;
    check_out_time: string;
    sitting_room: number;
    city: string;
    state: string;
    kitchen: number;
    status: string;
    features: {id: number; info: string}[];
    floors: {id: number; name: string; gender: string}[];
    images: {id: number; image: string}[];
    rooms: {name: string}[];
}

interface HouseState {
    loading: boolean;
    error: string | null;
    success: boolean | null;
    houses : House[] | null;
}

const initialState = {
    loading: false,
    error: null,
    success: false,
    houses: null
}

const housesReducer = (state: HouseState = initialState, action: HousesAction): HouseState => {
    switch (action.type){
        case ActionType.GET_HOUSES_START:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
                houses: null
            }
        case ActionType.GET_HOUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                houses: action.payload
            }
        case ActionType.GET_HOUSES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                houses: null
            }
        default:
            return state;

    }
}

export default housesReducer