import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { SearchBookingsAction } from "../actions/search.bookings.action";
import axios from "../../axios-fetches";
import { detectError } from "../../utilities";

export const getBookings = (
  data: {
    search_text: string;
    check_in_date: string;
    check_out_date: string;
    created_start_date: string;
    created_end_date: string;
    status: string;
    payment_method: string;
    gender_type: string;
    country: string;
    house: string;
    bed: string;
  },
  params: { page: number }
) => {
  return async (dispatch: Dispatch<SearchBookingsAction>) => {
    dispatch({
      type: ActionType.SEARCH_BOOKINGS_START,
      search_text: data.search_text,
      check_in_date: data.check_in_date,
      check_out_date: data.check_out_date,
      created_start_date: data.created_start_date,
      created_end_date: data.created_end_date,
      status: data.status,
      payment_method: data.payment_method,
      gender_type: data.gender_type,
      country: data.country,
      house: data.house,
      bed: data.bed,
      page: params.page,
    });

    let bedRes;
    let roomRes = [];
    let bedError = "";
    try {
      bedRes = await axios.get(
        `/api/admin/bookings/?ordering=-booking__created_at&page=${params.page}&search=${data.search_text}&booking__check_in_date__gte=${data.check_in_date}&booking__check_out_date__lte=${data.check_out_date}&booking__created_at__gte=${data.created_start_date}&booking__created_at__lte=${data.created_end_date}&gender=${data.gender_type}&status=${data.status}&payment_method=${data.payment_method}&customer_country=${data.country}&house_name=${data.house}&bed_name=${data.bed}`
      );
    } catch (e) {
      console.log(e);
      const message = detectError(e);
      bedError = message;
      dispatch({
        type: ActionType.SEARCH_BOOKINGS_ERROR,
        payload: message,
      });
    }

    if (bedError === "" && data.house !== "") {
      try {
        const res = await axios.get(
          `/api/admin/bookings/?ordering=-booking__created_at&page=${params.page}&search=${data.search_text}&booking__check_in_date__gte=${data.check_in_date}&booking__check_out_date__lte=${data.check_out_date}&booking__created_at__gte=${data.created_start_date}&booking__created_at__lte=${data.created_end_date}&gender=${data.gender_type}&status=${data.status}&payment_method=${data.payment_method}&customer_country=${data.country}&room_house_name=${data.house}&bed_name=${data.bed}`
        );
        roomRes = res.data.results;
      } catch (e) {
        // console.log(e);
        roomRes = [];
      }
    }

    if (bedRes && bedError === "") {
      const payload = {
        ...bedRes.data,
        results: bedRes.data.results.concat(roomRes),
      };
      dispatch({
        type: ActionType.SEARCH_BOOKINGS_SUCCESS,
        payload,
      });
    }
  };
};

export const clearBookings = () => {
  return async (dispatch: Dispatch<SearchBookingsAction>) => {
    dispatch({
      type: ActionType.SEARCH_CLEAR_BOOKINGS,
    });
  };
};
