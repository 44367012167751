import {ActionType} from "../action-types";
import {GetStatusBookingAction} from "../actions/status.bookings.action"

interface StatusState {
    loading: boolean,
    error: string | null,
    success: boolean,
    data: {
        check_in: any[],
        check_out: any[],
        current_bookings: any[],
        house_name: string
    } | null
}

const initialState = {
    loading: false,
    error: null,
    success: false,
    data: null
}

const statusBookingReducer = (state: StatusState = initialState, action: GetStatusBookingAction): StatusState =>
{
    switch (action.type) {
        case ActionType.GET_STATUS_BOOKINGS_START:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
                data: null
            }
        case ActionType.GET_STATUS_BOOKINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                data: action.payload
            }
        case ActionType.GET_STATUS_BOOKINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                data: null
            }
        case ActionType.CLEAR_STATUS_BOOKINGS:
            return {
                loading: false,
                error: null,
                success: false,
                data: null
            }
        default:
            return state
    }
}

export default statusBookingReducer