import React, { useCallback, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form as Formikform, Field, ErrorMessage } from "formik";
import { RouteComponentProps, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import "./index.scss";
type Props = {};

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string().required("Required"),
});

const Login: React.FC<Props & RouteComponentProps> = (props) => {
  const { signinUser, clearError } = useActions();
  const { loading, error, success, user } = useTypedSelector(
    (state) => state.auth
  );

  // console.log(loading, error, success, user);

  const signIn = useCallback(
    (user: { email: string; password: string }) => {
      signinUser(user);
    },
    [signinUser]
  );

  const clearSignInError = useCallback(() => {
    clearError();
  }, [clearError]);

  let redirect: JSX.Element | null = null;
  if (success && user?.username) {
    toast.success(`Welcome ${user.username}`);
    redirect = <Redirect to={"/admin"} />;
  }

  let message: JSX.Element | null = null;
  if (error) {
    message = (
      <div className="py-1 w-100 message bg-light">
        <h3 className="text-center text-danger">{error}</h3>
      </div>
    );
  }

  useEffect(() => {
    return () => {
      clearSignInError();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {redirect}
      <div className="login">
        <Container className="mt-5">
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
              <div className="form p-3">
                <div className="mb-3 w-100 d-flex justify-content-center image_container">
                  <img src="/images/plab2_logo_2.png" alt="logo" />
                </div>
                <div className="my-2 message_cover">{message}</div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    signIn(values);
                  }}
                >
                  {(formik) => {
                    return (
                      <Formikform>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Field
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="email"
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-5"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Field
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            name="password"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="password"
                          />
                        </Form.Group>
                        {loading ? (
                          <div className="w-100 text-center">
                            <Spinner animation="grow" variant="dark" />
                          </div>
                        ) : (
                          <Button
                            disabled={!formik.isValid}
                            className="w-100"
                            variant="dark"
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </Formikform>
                    );
                  }}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
