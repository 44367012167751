import { ActionType } from "../action-types";
import { RefundsAction } from "../actions/refunds.action";

type RefundState = {
  loading: boolean;
  error: string | null;
  success: boolean;
  refunds: any[] | null;
  page: number;
  perPage: number;
  total: number;
  searchText: string;
  searchResults: any[] | null;
  searchResultsTotal: number;
  searchResultsLoading: boolean;
  searchResultsError: string | null;
  searchResultsPage: number;
  searchResultsPerPage: number;
  refundsView: string;
};

const initialState: RefundState = {
  loading: false,
  error: null,
  success: false,
  refunds: null,
  page: 1,
  perPage: 30,
  total: 0,
  searchText: "",
  searchResults: null,
  searchResultsTotal: 0,
  searchResultsLoading: false,
  searchResultsError: null,
  searchResultsPage: 1,
  searchResultsPerPage: 30,
  refundsView: "normal",
};

const refundsReducer = (
  state: RefundState = initialState,
  action: RefundsAction
) => {
  switch (action.type) {
    case ActionType.FETCH_REFUNDS_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        refunds: null,
        page: action.page,
      };
    case ActionType.FETCH_REFUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        refunds: action.refunds,
        total: action.total,
      };
    case ActionType.FETCH_REFUNDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
        staffs: null,
        page: 1,
        total: 0,
      };
    case ActionType.REFUNDS_SEARCH_START:
      return {
        ...state,
        searchResultsLoading: true,
        searchResultsError: null,
        searchResults: null,
        searchResultsPage: action.page,
        searchText: action.search_text,
      };
    case ActionType.REFUNDS_SEARCH_SUCCESS:
      return {
        ...state,
        searchResultsLoading: false,
        searchResultsError: null,
        searchResults: action.payload.results,
        searchResultsTotal: action.payload.count,
      };
    case ActionType.REFUNDS_SEARCH_ERROR:
      return {
        ...state,
        searchResultsLoading: false,
        searchResultsError: action.payload,
        searchResults: null,
        searchResultsPage: 1,
        searchResultsTotal: 0,
      };
    case ActionType.SET_REFUND_VIEW:
      return {
        ...state,
        refundsView: action.view,
      };
    case ActionType.SEARCH_CLEAR_REFUNDS:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        refunds: null,
        page: 1,
        perPage: 30,
        total: 0,
        searchText: "",
        searchResults: null,
        searchResultsTotal: 0,
        searchResultsLoading: false,
        searchResultsError: null,
        searchResultsPage: 1,
        searchResultsPerPage: 30,
        refundsView: "normal",
      };
    default:
      return state;
  }
};

export default refundsReducer;
