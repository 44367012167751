import React from "react";
import "./index.scss";
type Props = {};

const LandingPage: React.FC<Props> = (props: Props) => {
  return (
    <div className="App">
      <div className="box">
        <h1>Hello Doctor!</h1>
        <p>
          Please this site has been moved{" "}
          <a href="https://plab2accommodations.co.uk/">here</a>
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
