import axios from "axios";

// const CancelToken = axios.CancelToken;
// export let cancel;

const instance = axios.create({
  baseURL: "https://plab2-prod.herokuapp.com",
  // baseURL: "http://localhost:8000",
  timeout: 580000,
});

// instance.defaults.withCredentials = true;

// Can add interceptors here
// interceptors: functions defined globally
// useful for setting common headers
// Logging responses
// Global handling of errors
// Executed for every request in any component leaving the app and every
// response returned

instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error?.response?.data);
    return Promise.reject(error);
  }
);

// Set interceptors differently for responses
instance.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
