import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import axios from "../../client-axios";
import { SearchEntityAction } from "../actions/search.action";
import { detectError } from "../../utilities";

export const getEntities = (
  data: {
    check_in_date: string;
    check_out_date: string;
    room_type: string;
    exam_date?: string;
    not_for_exam: boolean;
  },
  params?: {
    page?: number;
    cost?: string;
    house?: string;
    floor?: string;
    bedRoomType?: string;
  }
) => {
  return async (dispatch: Dispatch<SearchEntityAction>) => {
    //dispatching extra data for search page form
    dispatch({
      type: ActionType.SEARCH_ENTITY_START,
      check_in_date: data.check_in_date,
      check_out_date: data.check_out_date,
      room_type: data.room_type,
      exam_date: data.exam_date ? data.exam_date : null,
      not_for_exam: data.not_for_exam,
    });

    try {
      let to_api;
      if (data.not_for_exam) {
        to_api = {
          check_in_date: data.check_in_date,
          check_out_date: data.check_out_date,
          room_type: data.room_type,
        };
      } else {
        to_api = {
          check_in_date: data.check_in_date,
          check_out_date: data.check_out_date,
          room_type: data.room_type,
          exam_date: data.exam_date,
        };
      }

      // console.log(`http://localhost:8000/api/search/?page=${params?.page ? params?.page : ""}&cost=${params?.cost ? params?.cost : ""}&floor=${params?.floor ? params?.floor : ""}&house=${params?.house ? params?.house : ""}&bed_room_type=${params?.bedRoomType ? params?.bedRoomType : ""}`);
      const res = await axios.post(
          `/api/search/?page=${params?.page ? params?.page : ""}&cost=${params?.cost ? params?.cost : ""}&floor=${params?.floor ? params?.floor : ""}&house=${params?.house ? params?.house : ""}&bed_room_type=${params?.bedRoomType ? params?.bedRoomType : ""}`,
        to_api
      );
      dispatch({
        type: ActionType.SEARCH_ENTITY_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      // console.log(e);
      const message = detectError(e);
      dispatch({
        type: ActionType.SEARCH_ENTITY_ERROR,
        payload: message,
      });
    }
  };
};

export const clearClientEntities = () => {
  return async (dispatch: Dispatch<SearchEntityAction>) => {
    dispatch({
      type: ActionType.SEARCH_CLEAR_ENTITY,
    });
  };
};

export const clearEntitiesError = () => {
  return async (dispatch: Dispatch<SearchEntityAction>) => {
    dispatch({
      type: ActionType.SEARCH_ENTITY_CLEAR_ERROR,
    });
  };
};