import { ActionType } from "../action-types";
import { SearchBookingsAction } from "../actions/search.bookings.action";

interface BookingsState {
  loading: boolean;
  error: string | null;
  success: boolean;
  search_text: string;
  check_in_date: string;
  check_out_date: string;
  created_start_date: string;
  created_end_date: string;
  status: string;
  payment_method: string;
  gender_type: string;
  country: string;
  house: string;
  bed: string;
  page: number;
  bookings: {
    results: any[];
    count: number;
    next: any;
    previous: any;
  } | null;
}

const initialState = {
  loading: false,
  error: null,
  success: false,
  search_text: "",
  check_in_date: "",
  check_out_date: "",
  created_start_date: "",
  created_end_date: "",
  status: "",
  payment_method: "",
  gender_type: "",
  country: "",
  house: "",
  bed: "",
  page: 1,
  bookings: null,
};

const searchBookingsReducer = (
  state: BookingsState = initialState,
  action: SearchBookingsAction
): BookingsState => {
  switch (action.type) {
    case ActionType.SEARCH_BOOKINGS_START:
      return {
        ...state,
        search_text: action.search_text,
        check_in_date: action.check_in_date,
        check_out_date: action.check_out_date,
        created_start_date: action.created_start_date,
        created_end_date: action.created_end_date,
        status: action.status,
        payment_method: action.payment_method,
        gender_type: action.gender_type,
        country: action.country,
        house: action.house,
        bed: action.bed,
        page: action.page,
        loading: true,
        error: null,
        success: false,
        bookings: null,
      };
    case ActionType.SEARCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        bookings: action.payload,
      };
    case ActionType.SEARCH_BOOKINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
        bookings: null,
      };
    case ActionType.SEARCH_CLEAR_BOOKINGS:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        search_text: "",
        check_in_date: "",
        check_out_date: "",
        created_start_date: "",
        created_end_date: "",
        status: "",
        payment_method: "",
        gender_type: "",
        country: "",
        house: "",
        bed: "",
        page: 1,
        bookings: null,
      };
    default:
      return state;
  }
};

export default searchBookingsReducer;
