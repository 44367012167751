// export const devURL = "http://localhost:8000"
export const devURL = "";

export const detectError = (error_obj: any): string => {
  let message = "Something went wrong, please try again";
  if (error_obj.response) {
    console.dir(error_obj);
    if (typeof error_obj.response.data === "object") {
      message = "";
      for (const [key, value] of Object.entries(error_obj.response.data)) {
        if (Array.isArray(value)) {
          message += `${key}: ${value.join("\n")}\n`;
        } else {
          message += `${key}: ${
            Array.isArray(value) ? value.join("\n") : value
          }\n`;
        }
        message += "\n";
      }
    }
  }
  return message;
};

export const combineBeds = (beds: any[] | undefined) => {
  const rooms = [];

  if (!beds) {
    return;
  }

  for (let i = 0; i < beds.length; i++) {
    if (
      beds[i].room.type.toLowerCase() === "Single Room".toLowerCase() ||
      beds[i].room.type.toLowerCase() === "Large Room".toLowerCase()
    ) {
      const room = beds[i].room;
      room.beds = [beds[i].name];
      room.bed_ids = [beds[i].id];
      rooms.push(room);
    }
    if (
      beds[i].room.type.toLowerCase() === "Double Room".toLowerCase() ||
      beds[i].room.type.toLowerCase() === "Ensuite Room".toLowerCase()
    ) {
      //check if room already in rooms list
      const idx = rooms.findIndex((room) => room.id === beds[i].room.id);
      if (idx !== -1) {
        continue;
      }
      const room_id = beds[i].room.id;
      const room = beds[i].room;
      //get other rooms
      const otherRooms = beds.filter((bed) => bed.room.id === room_id);
      if (otherRooms.length < 2) {
        continue;
      }
      room.beds = otherRooms.map((bedRoom) => {
        //return bed name
        return bedRoom.name;
      });
      room.bed_ids = otherRooms.map((bedRoom) => {
        //return bed name
        return bedRoom.id;
      });
      rooms.push(room);
    }
    if (
      beds[i] &&
      beds[i].room.type.toLowerCase() === "Triple Room".toLowerCase()
    ) {
      const idx = rooms.findIndex((room) => room.id === beds[i].room.id);
      if (idx !== -1) {
        continue;
      }
      const room_id = beds[i].room.id;
      const room = beds[i].room;
      const otherRooms = beds.filter((bed) => bed.room.id === room_id);
      if (otherRooms.length < 3) {
        continue;
      }
      room.beds = otherRooms.map((bedRoom) => {
        // const idx = beds.findIndex((bed) => bed.room.id === room_id);
        // beds.splice(idx, 1);
        return bedRoom.name;
      });
      room.bed_ids = otherRooms.map((bedRoom) => {
        //return bed name
        return bedRoom.id;
      });
      rooms.push(room);
    }
  }

  return rooms;
};

export function numberWithCommas(x: string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function startOfWeek(date: Date) {
  let diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  const dt = new Date(date.setDate(diff));
  return dt.toDateString();
}

export function endOfWeek(date: Date) {
  let lastday = date.getDate() - (date.getDay() - 1) + 6;
  const dt = new Date(date.setDate(lastday));
  return dt.toDateString();
}

export function getDiffDays(check_in_date: string, check_out_date: string) {
  const check_in = new Date(check_in_date!);
  const check_out = new Date(check_out_date!);

  //@ts-ignore
  let diffTime = Math.abs(check_out - check_in);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  return diffDays;
}

export function getPriceRoom(
  check_in: string | null,
  check_out: string | null,
  main_entity: any
) {
  if (!check_in || !check_out || !main_entity) {
    return 0;
  }

  const diffDays = getDiffDays(check_in!, check_out!);

  return diffDays >= 26
    ? main_entity?.platinum_price
    : diffDays >= 14 && diffDays < 26
    ? main_entity?.gold_price
    : main_entity?.classic_price;
}

export function getPriceBed(
  check_in: string | null,
  check_out: string | null,
  main_entity: any
) {
  if (!check_in || !check_out || !main_entity) {
    return 0;
  }

  const diffDays = getDiffDays(check_in!, check_out!);

  return diffDays >= 26
    ? main_entity?.room.platinum_price
    : diffDays >= 14 && diffDays < 26
    ? main_entity?.room.gold_price
    : main_entity?.room.classic_price;
}

class Range {
  constructor(public start: Date, public end: Date) {}
}

export function getOverlap(
  testDateStart: Date,
  testDateEnd: Date,
  mainDateStart: Date,
  mainDateEnd: Date
): number {
  // Define the Range object

  // Create the date ranges
  let userNewTime = new Range(testDateStart, testDateEnd);
  let currentBooking = new Range(mainDateStart, mainDateEnd);

  // Find the latest start and earliest end
  let latestStart = new Date(
    Math.max(userNewTime.start.getTime(), currentBooking.start.getTime())
  );
  let earliestEnd = new Date(
    Math.min(userNewTime.end.getTime(), currentBooking.end.getTime())
  );

  // Calculate the overlap in milliseconds, convert to days
  let delta = earliestEnd.getTime() - latestStart.getTime();
  let overlap = Math.round(delta / (1000 * 60 * 60 * 24) + 1);

  return Math.max(0, overlap);
}
