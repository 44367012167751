export enum ActionType {
  AUTH_START = "auth_start",
  AUTH_SUCCESS = "auth_success",
  AUTH_ERROR = "auth_error",
  CLEAR_ERROR = "clear_error",
  CURRENT_USER_START = "current_user_start",
  CURRENT_USER_SUCCESS = "current_user_success",
  CURRENT_USER_ERROR = "current_user_error",
  SIGNOUT_START = "signout_start",
  SIGNOUT_SUCCESS = "signout_success",
  SIGNOUT_ERROR = "signout_error",
  GET_HOUSES_START = "get_houses_start",
  GET_HOUSES_SUCCESS = "get_houses_success",
  GET_HOUSES_ERROR = "get_houses_error",
  SEARCH_ENTITY_START = "search_entity_start",
  SEARCH_ENTITY_SUCCESS = "search_entity_success",
  SEARCH_ENTITY_ERROR = "search_entity_error",
  SEARCH_ENTITY_CLEAR_ERROR = "search_entity_clear_error",
  SEARCH_CLEAR_ENTITY = "search_clear_entity",
  SEARCH_BOOKINGS_START = "search_bookings_start",
  SEARCH_BOOKINGS_SUCCESS = "search_bookings_success",
  SEARCH_BOOKINGS_ERROR = "search_bookings_error",
  SEARCH_CLEAR_BOOKINGS = "search_clear_bookings",
  GET_STATUS_BOOKINGS_START = "get_status_bookings_start",
  GET_STATUS_BOOKINGS_SUCCESS = "get_status_bookings_success",
  GET_STATUS_BOOKINGS_ERROR = "get_status_bookings_error",
  CLEAR_STATUS_BOOKINGS = "clear_status_bookings",
  FETCH_REFUNDS_START = "fetch_refunds_start",
  FETCH_REFUNDS_SUCCESS = "fetch_refunds_success",
  FETCH_REFUNDS_ERROR = "fetch_refunds_error",
  REFUNDS_SEARCH_START = "refunds_search_start",
  REFUNDS_SEARCH_SUCCESS = "refunds_search_success",
  REFUNDS_SEARCH_ERROR = "refunds_search_error",
  SEARCH_CLEAR_REFUNDS = "search_clear_refunds",
  SET_REFUND_VIEW = "set_refund_view",
}
