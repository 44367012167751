import axios from "../../axios-fetches";
import { detectError } from "../../utilities";
import { ActionType } from "../action-types";
import { Dispatch } from "redux";
import { RefundsAction } from "../actions/refunds.action";

export const getRefunds = (page: number) => {
  return async (dispatch: Dispatch<RefundsAction>) => {
    dispatch({
      type: ActionType.FETCH_REFUNDS_START,
      page: page,
    });

    try {
      // send request to get refunds
      const response = await axios.get(
        `/api/admin/refunds/?page=${page}&ordering=-created_at`
      );
      dispatch({
        type: ActionType.FETCH_REFUNDS_SUCCESS,
        refunds: response.data.results,
        total: response.data.count,
      });
    } catch (error) {
      const message = detectError(error);
      dispatch({
        type: ActionType.FETCH_REFUNDS_ERROR,
        payload: message,
      });
    }
  };
};

export const searchRefunds = (query: string, page: number) => {
  return async (dispatch: Dispatch<RefundsAction>) => {
    dispatch({
      type: ActionType.REFUNDS_SEARCH_START,
      search_text: query,
      page: page,
    });

    try {
      const response = await axios.get(
        `/api/admin/refunds/?page=${page}&ordering=-created_at&search=${query}`
      );
      dispatch({
        type: ActionType.REFUNDS_SEARCH_SUCCESS,
        payload: {
          results: response.data.results,
          count: response.data.count,
        }, // response data
      });
    } catch (error) {
      const message = detectError(error);
      dispatch({
        type: ActionType.REFUNDS_SEARCH_ERROR,
        payload: message,
      });
    }
  };
};

export const setRefundsView = (view: string) => {
  return async (dispatch: Dispatch<RefundsAction>) => {
    dispatch({
      type: ActionType.SET_REFUND_VIEW,
      view: view,
    });
  };
};

export const clearSearch = () => {
  return async (dispatch: Dispatch<RefundsAction>) => {
    dispatch({
      type: ActionType.SEARCH_CLEAR_REFUNDS,
    });
  };
};
